<template>
  <section id="ParkingLots">
    <div class="parkingLotsOverview__background"></div>
    <div class="mobile-overlay mobile"></div>
    <Overview :overviewData="overviewData" />
    <ParkingLotsMobile class="mobile" />
  </section>
</template>
  
  <script>
import Overview from "@/components/global/Overview";
import { getParkingLotsData } from "@/data/sectionData/eventSpaces/ParkingLots";
import ParkingLotsMobile from "@/components/sections/EventSpaces/ParkingLots/ParkingLotsMobile";

export default {
  name: "ParkingLotsOverview",
  components: {
    Overview,
    ParkingLotsMobile,
  },
  data() {
    return {
      overviewData: getParkingLotsData(),
    };
  },
};
</script>
  
  <style lang="scss" scoped>
#ParkingLots {
  width: 100%;
  position: relative;
  @media (max-width: 993px) {
    position: relative;
    width: 100vw;
    min-height: 100vh;
    background-image: url("~@/assets/images/EventSpaces/parking-lots-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  .parkingLotsOverview__background {
    background-image: url("~@/assets/images/EventSpaces/parking-lots-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -10;
    @media (max-width: $lg) {
      display: none;
    }
  }
  .mobile-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    background-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.8) 48%,
      rgba(0, 0, 0, 0.72) 88%
    );
  }
  .mobile {
    position: relative;
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>